/* eslint-disable import/order */
import React, { Suspense, useRef } from "react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import lazyRetryImport from "helpers/lazyRetryImport"

import "polyfills"

import reportWebVitals from "./reportWebVitals"

// trigger build

const Index = () => {
  const App = lazyRetryImport(() => import("./App"))
  const client = useRef(new QueryClient())

  return (
    <React.StrictMode>
      <QueryClientProvider client={client.current}>
        <BrowserRouter>
          <Suspense>
            <App />
          </Suspense>
        </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
  )
}

// The (!) at the end of getElementById implies that the result is assumed to be not null
const root = createRoot(document.getElementById("root")!)
root.render(<Index />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
